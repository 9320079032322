<template>
  <b-modal
    @shown="updateItem"
    title="檢視公費生資訊"
    id="viewStudentInfo"
    size="xl"
    @hide="doSometing"
  >
    <b-container fluid>
      <header class="dtc-grid-header my-dark">
        <div v-for="(item, i) in headers" :key="`headersOne${i}`" :title="item">
          {{ item }}
        </div>
      </header>
      <main
        class="dtc-grid-header2"
        v-for="(item, i) in items"
        :key="i"
        style="color:#39312E;"
        :style="
          i % 2 == 0
            ? 'background-color:#ffffff ;'
            : 'background-color: #efefef;'
        "
      >
        <div style="cursor: pointer;padding-top:3px;">
          {{ +i + 1 }}
        </div>
        <div :id="`itemOne${i}`" :title="item.MajorName">
          {{ item.MajorName || "暫無資料" }}
        </div>
        <div :id="`itemOne${i}`" :title="item.GradeName">
          {{ item.GradeName || "暫無資料" }}
        </div>
        <div :id="`itemOne${i}`" :title="item.Name">
          {{ item.Name || "暫無資料" }}
        </div>
        <div :id="`itemOne${i}`" :title="item.StartYear">
          {{ item.StartYear || "暫無資料" }}
        </div>

        <div :title="item.EndYear">{{ item.EndYear || "暫無資料" }}</div>
        <div :title="item.ServiceItem">
          {{ item.ServiceItem || "暫無資料" }}
        </div>
        <div :title="item.ServiceDetail">
          {{ item.ServiceDetail || "暫無資料" }}
        </div>
      </main>
      <footer class="dtx-footer">
        <b-pagination
          v-model="currentPageNum"
          :total-rows="rowCount"
          :per-page="pagingRowPerPage"
          aria-controls="dataTable1"
          align="center"
          class="mt-2"
        >
          <span slot="first-text">
            <i class="fas fa-fast-backward"></i>
          </span>
          <span slot="prev-text">
            <i class="fas fa-step-backward"></i>
          </span>
          <span slot="next-text">
            <i class="fas fa-step-forward"></i>
          </span>
          <span slot="last-text">
            <i class="fas fa-fast-forward"></i>
          </span>
          <span slot="page" slot-scope="{ page, active }">
            <b v-if="active">{{ page }}</b>
            <i v-else>{{ page }}</i>
          </span>
        </b-pagination>
        <b-select
          :options="rows"
          class="mt-2"
          v-model="pagingRowPerPage"
        ></b-select>
        <div class="mt-3">{{ totalCountStr }}</div>
      </footer>
    </b-container>

    <template v-slot:modal-footer>
      <div class="w-100">
        <b-button
          variant="info"
          class="float-right ml-3 mr-3"
          @click="$bvModal.hide('viewStudentInfo')"
          >關閉</b-button
        >
      </div>
    </template>
    <div class="footer-word">
      備註:如欲更改公費生年級及服務現況資訊，請至系統管理/公費生資料維護頁面中做修改。
    </div>
  </b-modal>
</template>

<script>
import { store } from "@/store/global.js";
import moment from "moment";
import buildQuery from "odata-query";
const headers = [
  "序",
  "學系",
  "年級",
  "姓名",
  "入學年",
  "畢業年",
  "服務情況",
  "服務現況",
];
const chineseNumber = [
  {
    value: 1,
    text: "一",
  },
  {
    value: 2,
    text: "二",
  },
  {
    value: 3,
    text: "三",
  },
  {
    value: 4,
    text: "四",
  },
  {
    value: 5,
    text: "五",
  },
  {
    value: 6,
    text: "六",
  },
  {
    value: 7,
    text: "七",
  },
  {
    value: 8,
    text: "八",
  },
  {
    value: 9,
    text: "九",
  },
];

const rows = [10, 20, 50];
export default {
  name: "ViewStudentInfo",
  data() {
    return {
      item: {},
      variants: [
        "warning",
        "dark",
        "light",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],
      items: [],
      headers,
      currentPageNum: 1,
      rowCount: 0,
      pagingRowPerPage: 20,
      totalCountStr: "",
      rows,
      chineseNumber,
    };
  },
  computed: {},
  methods: {
    doSometing() {
      //   this.$emit("updateRuralProgress");
    },
    updateItem() {
      this.item = { ...store.editItem };
      this.getData();
    },
    async getData() {
      //odata3 qs
      const page = this.currentPageNum;
      const skip = (page - 1) * this.pagingRowPerPage;
      const top = this.pagingRowPerPage;
      // const orderBy = this.orderBy;
      const orderBy = ["MajorName", "StartYear desc"];
      const obj = { top, skip, orderBy };
      let qs = buildQuery(obj);
      qs = `${qs}&$filter=Category eq '1'and substringof('${this.item.SchoolCode}' , SchoolCode ) and ServiceItem eq '在學中'`;

      try {
        let { Items, Count } = await window.axios.get(
          `StudentInfo/GetStudentHead${qs}`
        );

        this.items = { ...Items };

        this.rowCount = Count;
        this.totalCountStr = `共${Count} 筆`;
      } catch (error) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
  },
  mounted() {},
  watch: {
    currentPageNum(v) {
      this.currentPageNum = v;
      this.getData().catch((e) => {});
    },
    pagingRowPerPage(v) {
      this.getData().catch((e) => {});
    },
  },
};
</script>

<style scoped lang="scss">
.input-group {
  margin-bottom: 10px;
}
.dtc-grid-header,
.dtc-grid-header2 {
  // width: 2230px;
  display: grid;
  grid-template-columns: 80px 200px 100px 130px 130px 160px 220px 1fr;
  grid-auto-flow: column;
  text-align: center;
  border-right: 0px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding: 4px 0;
    border-right: 1px solid #dee2e5;
    border-bottom: 1px solid #dee2e5;
    display: -webkit-box;
    height: 36px;
  }
  > div:first-child {
    border-left: 1px solid #dee2e5;
  }
}
.my-dark {
  > div {
    // background: var(--gray);
    background: #333;
    color: white;
  }
}
.dtc-grid-header {
  > div {
    line-height: 30px;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 6px;
    padding-top: 7px;
  }
}

::v-deep .modal-header {
  background: #7e2ca3;
  color: #ffffff;
  .close {
    color: #ffffff;
  }
}
::v-deep .modal-content {
  margin-left: -90px;
  width: 116%;
}
.footer-word {
  position: absolute;
  bottom: -46px;
  left: 30px;
  font-family: Microsoft JhengHei, Helvetica;
  font-weight: bolder;
}
.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}
</style>
